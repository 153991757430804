import { Chunk } from '@/store/modules/health-data/types'
import { DocumentLimitMatch } from '@/text-processing/types'

interface JustificationChunk {
  text: string
  isHighlighted: boolean
  isDoubt: boolean
  isNegative: boolean
}
export interface Justification {
  index: number
  chunks: JustificationChunk[]
}

export function getChunkClass(chunk: Chunk): string[] {
  const classes = []
  if (chunk.isNegative) {
    classes.push('health-data-negation')
  } else if (chunk.isDoubt) {
    classes.push('health-data-doubt')
  } else if (chunk.isHighlighted) {
    classes.push('health-data-keyword')
  }

  return classes
}

// merge justifications if too close
export function getJustifications(text: string, limits: DocumentLimitMatch[]): Justification[] {
  // nb character before and after keyword
  const justificationContextSize = 100
  let t = ''
  const justifications = []
  let currentIndice = 0
  let currentJustification: Justification = {
    index: currentIndice,
    chunks: []
  }

  let merged = false
  for (let i = 0; i < limits.length; i++) {
    if (i !== 0 && !merged) {
      justifications.push(currentJustification)
      currentJustification = {
        index: currentIndice,
        chunks: []
      }
    }

    // before keyword
    t = ''
    if (!merged) {
      if (currentIndice + justificationContextSize > limits[i].textIndices[0]) {
        t = text.slice(currentIndice, limits[i].textIndices[0])
      } else {
        if (currentIndice === 0) {
          t = ' ...'
        }
        t += text.slice(limits[i].textIndices[0] - justificationContextSize, limits[i].textIndices[0])
      }
      currentJustification.chunks.push({
        text: t,
        isHighlighted: false,
        isDoubt: false,
        isNegative: false,
      })
    }

    // keyword
    merged = false
    currentJustification.chunks.push({
      text: text.slice(limits[i].textIndices[0], limits[i].textIndices[1]),
      isHighlighted: true,
      isDoubt: limits[i].isDoubt,
      isNegative: limits[i].isNegative,
    })

    // after keyword
    const maxLimit = i === limits.length - 1 ? text.length : limits[i + 1].textIndices[0] - justificationContextSize
    if (limits[i].textIndices[1] + justificationContextSize > maxLimit) {
      t = text.slice(limits[i].textIndices[1], maxLimit + justificationContextSize)
      merged = true
    } else {
      t = text.slice(limits[i].textIndices[1], limits[i].textIndices[1] + justificationContextSize)
      if (i === limits.length - 1) {
        t += '... '
      }
    }
    currentJustification.chunks.push({
      text: t,
      isHighlighted: false,
      isDoubt: false,
      isNegative: false,
    })
    currentIndice = limits[i].textIndices[1]
  }
  justifications.push(currentJustification)

  return justifications
}
