<script>
import _ from 'lodash'

import { documentCategories, textualHealthDataCategories } from '@/stay-displayer/health-data/documentLabels'

export default {
  props: {
    filterData: { type: Object, required: true },
    isGlobalCondition : { type: Boolean, default: false }
  },
  emits: ['add-filter'],
  data() {
    return {
      presence: this.isGlobalCondition ? 'absent' : 'present',
      extendedSearch: false,
      includeAntecedents: false,
      includeFamilyAntecedents: false,
      includeNegations: false,
      hardAbsentContent: false,
      specifyWordDistance: false,
      specifyDocumentCategory: false,
      openTooltip: false,
      value: '',
      wordDistance: 0,
      documentCategory: null,
      textualReportCategories: { ...documentCategories, ...textualHealthDataCategories },
    }
  },
  computed: {
    filterOptions() {
      const subOpt = this.filterData.subFilters[0]
      const isGlobalOptions = subOpt.isGlobalOptions ? subOpt.isGlobalOptions : []
      const notGlobalOptions = subOpt.notGlobalOptions ? subOpt.notGlobalOptions : subOpt.options

      return _.intersection(
        subOpt.options,
        this.isGlobalCondition ? isGlobalOptions : notGlobalOptions
      )
    },
  },
  watch: {
    presence() {
      this.resetModifiers()
    },
  },
  mounted() {
    this.resetModifiers()
  },
  methods: {
    resetModifiers() {
      this.includeAntecedents = false
      this.includeFamilyAntecedents = false
      this.includeNegations = false
      this.hardAbsentContent = !this.isGlobalCondition
      this.specifyWordDistance = false
      this.specifyDocumentCategory = false
      this.wordDistance = 0
      this.documentCategory = null
    },
    addFilter() {
      let filterValue = this.value.trim()
      if (!filterValue) {
        return
      }
      this.hardAbsentContent = !this.isGlobalCondition
      const presence = this.presence === 'absent' && !this.hardAbsentContent ? 'softAbsent' : this.presence
      const modifiers = []
      if (this.includeAntecedents && (this.presence === 'present' || this.hardAbsentContent)) {
        modifiers.push('a')
      }
      if (this.includeFamilyAntecedents && (this.presence === 'present' || this.hardAbsentContent)) {
        modifiers.push('f')
      }
      if (this.includeNegations && (this.presence === 'present' || this.hardAbsentContent)) {
        modifiers.push('n')
      }
      if (this.specifyWordDistance) {
        modifiers.push(`wd${this.wordDistance}`)
      }

      // We build filterValue. If there are modifiers, they are put before a "Unit separator" (0x1F) character
      if (modifiers.length && (this.presence === 'present' || this.hardAbsentContent)) {
        filterValue = `${modifiers.join('_')}\x1F${this.value}`
      }

      let type = `${presence}Content`
      if (this.specifyDocumentCategory) {
        type += `__${this.documentCategory}`
      }

      this.$emit('add-filter', {
        name: this.filterData.name,
        type: type,
        value: filterValue,
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div
        class="input-group col-auto row no-gutters"
      >
        <select
          v-model="presence"
          name="textContentCriteria_presence"
          class="custom-select"
        >
          <option
            v-for="opt in filterOptions"
            :key="opt"
            :value="opt"
          >
            {{ filterData.subFilters[0].labels[opt] }}
          </option>
        </select>
      </div>
      <div
        class="input-group col-auto row no-gutters"
      >
        <select
          v-if="specifyDocumentCategory"
          v-model="documentCategory"
          class="custom-select"
        >
          <option
            v-for="(docCategory, idx) in textualReportCategories"
            :key="idx"
            :value="idx"
          >
            {{ docCategory }}
          </option>
        </select>
      </div>
      <div
        class="input-group col-auto row no-gutters"
      >
        <input
          v-model="value"
          name="textContentCriteria_input"
          class="form-control"
          @keyup.enter="addFilter"
        >
        <input
          v-if="specifyWordDistance"
          v-model="wordDistance"
          class="form-control col-1 text-center"
        >
        <div class="input-group-append">
          <button
            name="textContentCriteria_ok"
            class="col-auto btn btn-primary"
            type="button"
            @click="addFilter"
          >
            Ok
          </button>
        </div>
      </div>
      <div class="pl-5">
        <div v-if="extendedSearch && (presence === 'present' || hardAbsentContent)">
          <div class="form-check">
            <input
              id="include-antecedent-checkbox"
              v-model="includeAntecedents"
              type="checkbox"
              class="form-check-input"
            >
            <label
              for="include-antecedent-checkbox"
              class="form-check-label"
            >
              Inclure les antécédents
            </label>
          </div>
          <div class="form-check">
            <input
              id="include-family-antecedent-checkbox"
              v-model="includeFamilyAntecedents"
              type="checkbox"
              class="form-check-input"
            >
            <label
              for="include-family-antecedent-checkbox"
              class="form-check-label"
            >
              Inclure les antécédents familiaux
            </label>
          </div>
          <div class="form-check">
            <input
              id="include-negation-checkbox"
              v-model="includeNegations"
              type="checkbox"
              class="form-check-input"
            >
            <label
              for="include-negation-checkbox"
              class="form-check-label"
            >
              Inclure les négations
            </label>
          </div>
          <div class="form-check">
            <input
              id="include-word-distance-checkbox"
              v-model="specifyWordDistance"
              type="checkbox"
              class="form-check-input"
            >
            <label
              for="include-word-distance-checkbox"
              class="form-check-label"
            >
              Spécifier l'intervalle entre les mots
            </label>
          </div>
          <div class="form-check">
            <input
              id="document-category-checkbox"
              v-model="specifyDocumentCategory"
              type="checkbox"
              class="form-check-input"
            >
            <label
              for="document-category-checkbox"
              class="form-check-label"
            >
              Spécifier la catégorie de document
            </label>
          </div>
        </div>
        <button
          v-if="extendedSearch === false && (presence === 'present' || hardAbsentContent)"
          class="btn btn-link"
          @click="extendedSearch = true"
        >
          <small>Plus d'options…</small>
        </button>
      </div>
    </div>
  </div>
</template>
